import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { User } from '@/api-schema';

const listUsersQuery = gql`
  query ListUsers {
    users: listUsers {
      userId
      name
      email
      created
      status
    }
  }
`;

export const listUsers = async (
  apolloProvider: ApolloProvider
): Promise<User[]> => {
  const result = await apolloProvider.defaultClient.query({
    query: listUsersQuery,
    fetchPolicy: 'no-cache'
  });
  return result.data.users;
};
