import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { Entity } from '@/api-schema';
import { deepCopy } from '@/util/deep';

const listDraftEntitiesQuery = gql`
  query ListDraftEntities {
    draftEntities: listDraftEntities {
      path
      type
      label
      lastModified {
        by
        at
      }
    }
  }
`;

export const listDraftEntities = async (
  apolloProvider: ApolloProvider
): Promise<Entity[]> => {
  const result = await apolloProvider.defaultClient.query({
    query: listDraftEntitiesQuery,
    fetchPolicy: 'no-cache'
  });
  return deepCopy(result.data.draftEntities);
};
