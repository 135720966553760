
  import Vue from 'vue';
  import { Entity } from '@/api-schema';
  import EntityResult from '@/components/search/EntityResult.vue';
  import { None } from '@/util/types';

  interface Computed {
    showArrows: boolean;
    itemCols: number;
    groupClass: string;
    entityGroups: Entity[][];
  }

  interface Props {
    entities: Entity[];
    imageOnly?: boolean;
  }

  const colsMap = {
    xs: 6,
    sm: 4,
    md: 4,
    lg: 3,
    xl: 2
  };

  export default Vue.extend<None, None, Computed, Props>({
    name: 'EntityCarousel',
    props: {
      entities: {
        type: Array as () => Entity[],
        required: true
      },
      imageOnly: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      showArrows() {
        return this.$vuetify.breakpoint.mdAndUp;
      },
      itemCols() {
        return colsMap[this.$vuetify.breakpoint.name];
      },
      groupClass() {
        return this.$vuetify.breakpoint.smAndDown ? 'px-1 py-3' : 'px-16 py-3';
      },
      entityGroups() {
        const groupSize = 12 / this.itemCols;
        return this.entities.reduce<Entity[][]>(
          (groups: Entity[][], entity: Entity) => {
            if (groups[groups.length - 1].length >= groupSize) {
              groups.push([]);
            }
            groups[groups.length - 1].push(entity);
            return groups;
          },
          [[]] as Entity[][]
        );
      }
    },
    components: {
      EntityResult
    }
  });
