export const commonFields = `
      path
      redirect
      label
      slug
      type
      body
      tags
      published
      lastModified {
        by
        at
      }
      avatarUrl
      related {
        path
        label
        type
        body
        published
        avatarUrl
      }
`;

export const commonProposedRevisionFields = `
        username
        body
        tags
        lastModified {
          by
          at
        }
        avatarUrl
        related {
          path
          label
          type
          body
          published
          avatarUrl
        }
`;

export const articleFields = `
      birthDeathDates
      occurrences
      references
      links {
        type
        url
      }
      selectedRecordings
      selectedWorks
      yearsActive
      proposedRevisions {
        ${commonProposedRevisionFields}
        birthDeathDates
        occurrences
        references
        links {
          type
          url
        }
        selectedRecordings
        selectedWorks
        yearsActive
      }
`;

export const mediaFields = `
      provenance {
        origin
        url
      }
      mediaSources {
        mimeType
        url
        description
      }
      proposedRevisions {
        ${commonProposedRevisionFields}
      }
`;
