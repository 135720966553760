
  import Vue from 'vue';
  import { None } from '@/util/types';
  import { belongsToGroup } from '@/auth/cognito';
  import { ProposedEntityRevision } from '@/api-schema';

  interface Computed {
    tooltip: string;
    icon: string;
    disabled: boolean;
  }

  interface Props {
    value: boolean;
    proposedRevision: ProposedEntityRevision | undefined;
    activity?: boolean;
    isModified?: boolean;
    published?: boolean;
  }

  export default Vue.extend<None, None, Computed, Props>({
    name: 'EditModeSwitch',
    props: {
      value: {
        type: Boolean,
        required: true
      },
      activity: {
        type: Boolean,
        required: false
      },
      isModified: {
        type: Boolean,
        required: false
      },
      published: {
        type: Boolean,
        required: false
      },
      proposedRevision: {
        type: Object as () => ProposedEntityRevision | undefined,
        required: false
      }
    },
    computed: {
      tooltip() {
        if (this.value) {
          return 'Editing - click for preview';
        }
        if (this.disabled) {
          return 'Cannot edit another user\'s proposed revision\n\nApprove first and then edit the article';
        }
        if (!belongsToGroup('curators')) {
          return 'Viewing - click to edit your revision';
        }
        return 'Viewing - click to edit';
      },
      icon() {
        return this.value ? 'mdi-eye' : 'mdi-pencil';
      },
      disabled() {
        return belongsToGroup('curators') && !this.value && !!this.proposedRevision;
      }
    }
  });
