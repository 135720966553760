export const formatNumber = (n: number): string => (
  n < 1000
    ? `${n}`
    : `${Math.floor(n / 1000)},${formatNumber(n % 1000)}`
);

export const formatDate = (d?: number): string =>
  (d !== undefined ? new Date(d).toLocaleString() : '');

export const formatSeconds = (d?: number): string =>
  (d !== undefined ? `${Math.floor(d / 1000)}.${d % 1000} sec` : '');
