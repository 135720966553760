
  import Vue from 'vue';
  import { None } from '@/util/types';
  import { ProposedEntityRevision } from '@/api-schema';
  import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
  import { belongsToGroup } from '@/auth/cognito';

  interface Props {
    disabled?: boolean;
    proposedRevisions: ProposedEntityRevision[];
    saveChanges(): Promise<void>;
    revertChanges(): void;
  }

  interface Methods {
    confirmSaveChanges(): Promise<void>
  }

  export default Vue.extend<None, Methods, None, Props>({
    name: 'SaveAndRevert',
    components: { ConfirmationDialog },
    props: {
      disabled: {
        type: Boolean,
        required: false
      },
      proposedRevisions: {
        type: Array,
        required: true
      },
      saveChanges: {
        type: Function as unknown as () => () => Promise<void>,
        required: true
      },
      revertChanges: {
        type: Function as unknown as () => () => void,
        required: true
      }
    },
    methods: {
      async confirmSaveChanges() {
        if (belongsToGroup('curators') && this.proposedRevisions.length > 0) {
          const dialog = this.$refs.confirmationDialog as unknown as { getUserResponse: () => Promise<boolean> };
          const confirmed = await dialog.getUserResponse();
          if (!confirmed) {
            return;
          }
        }
        await this.saveChanges();
      }
    }
  });
