
  import Vue from 'vue';
  import { MetaInfo } from 'vue-meta';
  import UserDetails from '@/components/profile/UserDetails.vue';
  import SlwaImport from '@/components/admin/SlwaImport.vue';
  import ImportRequests from '@/components/admin/ImportRequests.vue';
  import DraftEntities from '@/components/admin/DraftEntities.vue';
  import ProposedRevisions from '@/components/admin/ProposedRevisions.vue';
  import UserManagement from '@/components/admin/UserManagement.vue';
  import { belongsToGroup } from '@/auth/cognito';
  import { GroupName } from '@/auth/types';

  interface Data {
    tab?: number;
  }

  interface Methods {
    redirectIfLoggedOut(): void;
    belongsToGroup(group: GroupName): boolean;
  }

  interface Computed {
    isCurator: boolean;
    isAdministrator: boolean;
  }

  export default Vue.extend<Data, Methods, Computed>({
    name: 'Profile',
    data() {
      return {
        tab: undefined as number | undefined
      };
    },
    methods: {
      redirectIfLoggedOut() {
        if (!this.$user.cognitoUser) {
          this.$router.push('/');
        }
      },
      belongsToGroup
    },
    authEvent() {
      this.redirectIfLoggedOut();
    },
    components: {
      DraftEntities,
      ImportRequests,
      UserManagement,
      SlwaImport,
      ProposedRevisions,
      UserDetails
    },
    metaInfo(): MetaInfo {
      return {
        title: 'User Profile',
        meta: [
          {
            name: 'og:title',
            content: 'User Profile'
          },
          {
            name: 'og:type',
            content: 'website'
          },
          {
            name: 'og:image',
            content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
          },
          {
            name: 'og:url',
            content: window.location.href
          },
          {
            name: 'og:author',
            content: 'WANMA'
          },
          {
            name: 'og:description',
            content: 'User profile page.'
          }
        ]
      };
    }
  });
