
  import Vue from 'vue';
  import { None } from '@/util/types';
  import { ProposedRevisionAction } from '@/api-schema';
  import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

  interface Props {
    disabled?: boolean;
    process: (action: ProposedRevisionAction) => Promise<void>;
  }

  interface Data {
    actionToConfirm: ProposedRevisionAction | undefined;
  }

  interface Methods {
    confirmAndProcess(action: ProposedRevisionAction): Promise<void>;
  }

  export default Vue.extend<Data, Methods, None, Props>({
    name: 'ApproveAndReject',
    components: { ConfirmationDialog },
    props: {
      disabled: {
        type: Boolean,
        required: false
      },
      process: {
        type: Function as unknown as () => (action: ProposedRevisionAction) => Promise<void>,
        required: true
      }
    },
    data() {
      return {
        actionToConfirm: undefined as ProposedRevisionAction | undefined
      };
    },
    methods: {
      async confirmAndProcess(action: ProposedRevisionAction) {
        this.actionToConfirm = action;
        const dialog = this.$refs.confirmationDialog as unknown as { getUserResponse: () => Promise<boolean> };
        if (await dialog.getUserResponse()) {
          await this.process(action);
        }
      }
    }
  });
