import algoliasearch from 'algoliasearch/lite';

const appId = process.env.VUE_APP_ALGOLIA_APP_ID as string;
const apiKey = process.env.VUE_APP_ALGOLIA_API_KEY as string;
const indexName = process.env.VUE_APP_ALGOLIA_INDEX_NAME as string;

const searchClient = algoliasearch(appId, apiKey);
const searchIndex = searchClient.initIndex(indexName);

export const getSearchClient = (): typeof searchClient => searchClient;

export const getSearchIndexName = (): string => indexName;

export const getSearchIndex = (): typeof searchIndex => searchIndex;
