import Vue from 'vue';
import InstantSearch from 'vue-instantsearch';
import { ObserveVisibility } from 'vue-observe-visibility';
import VTiptap from '@peepi/vuetify-tiptap';
import VueCognitoAuth from '@/auth';
import App from '@/App.vue';
import { buildRouter } from '@/plugins/router';
import { buildVuetify } from '@/plugins/vuetify';
import { buildApolloProvider } from '@/plugins/apollo';
import { initializeAuth } from '@/auth/cognito';

Vue.config.productionTip = false;

// Ideally this would be inside the `VueCognitoAuth` plugin, but it leads to a race condition, because plugins are not
// installed sequentially, so we do it here.
initializeAuth().then(() => {
  Vue.use(VueCognitoAuth);
  Vue.use(VTiptap);
  Vue.use(InstantSearch);
  Vue.directive('observe-visibility', ObserveVisibility);

  new Vue({
    router: buildRouter(),
    vuetify: buildVuetify(),
    apolloProvider: buildApolloProvider(),
    render: (h) => h(App)
  }).$mount('#app');
});
