
  import Vue from 'vue';
  import { MetaInfo } from 'vue-meta';
  import AppBar from '@/components/layout/AppBar.vue';
  import AcknowledgementOfCountry from '@/components/layout/AcknowledgementOfCountry.vue';
  import Footer from '@/components/layout/Footer.vue';
  import AuthNotifications from '@/components/layout/AuthNotifications.vue';

  export default Vue.extend({
    name: 'App',
    metaInfo(): MetaInfo {
      return {
        title: 'Loading',
        titleTemplate: '%s | Western Australian New Music Archive'
      };
    },
    components: {
      AppBar,
      AcknowledgementOfCountry,
      Footer,
      AuthNotifications
    }
  });
