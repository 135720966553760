import { Route } from 'vue-router';

export const isAuthPath = (path: string): boolean =>
  !!path && path !== '/user/profile' && path.substring(0, 6) === '/user/';

export const getRouteReturnPath = (route: Route): string => {
  if (route.query.returnPath) {
    return `${route.query.returnPath}`;
  }
  if (route.path && !isAuthPath(route.path)) {
    return route.path;
  }
  return '/';
};

const getAuthPath = (urlPath: string, route: Route, email: string): string => {
  const encodedReturnPath = encodeURIComponent(getRouteReturnPath(route));
  const encodedEmail = encodeURIComponent(email);
  return `${urlPath}?returnPath=${encodedReturnPath}&email=${encodedEmail}`;
};

export const getLoginPath = (route: Route, email = ''): string =>
  getAuthPath('/user/login', route, email);

export const getSignupPath = (route: Route, email = ''): string =>
  getAuthPath('/user/signup', route, email);

export const getSignupConfirmationPath = (route: Route, email = ''): string =>
  getAuthPath('/user/signup/confirm', route, email);

export const getRecoverPasswordPath = (route: Route, email = ''): string =>
  getAuthPath('/user/recover', route, email);

export const getRecoverPasswordConfirmationPath = (route: Route, email = ''): string =>
  getAuthPath('/user/recover/confirm', route, email);

export const getProfilePath = (): string => '/user/profile';
