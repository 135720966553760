import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { AvatarUpload, GetAvatarUploadInput } from '@/api-schema';

const getAvatarUploadQuery = gql`
  query GetAvatarUpload($input: GetAvatarUploadInput!) {
    upload: getAvatarUpload(input: $input) {
      displayUrl
      uploadUrl
    }
  }
`;

export const getAvatarUpload = async (
  apolloProvider: ApolloProvider,
  input: GetAvatarUploadInput
): Promise<AvatarUpload> => {
  const result = await apolloProvider.defaultClient.query({
    query: getAvatarUploadQuery,
    variables: { input },
    fetchPolicy: 'no-cache'
  });
  return result.data.upload;
};
