const isArray = (value: unknown): value is unknown[] => Array.isArray(value);

const stripMetadataElement = <T>(value: T | T[]): T | T[] => {
  if (isArray(value)) {
    return value.map((item) => stripMetadataElement(item)) as T[];
  }
  if (!!value && typeof value === 'object') {
    // eslint-disable-next-line no-use-before-define
    return stripMetadata(value as Record<string, unknown>) as T;
  }
  return value;
};

export const stripMetadata = <T extends Record<string, unknown> = Record<string, unknown>>(data: T): T =>
  Object.keys(data)
    .filter((key) => !key.startsWith('_'))
    .reduce<T>(
      (result, key) => ({ ...result, [key]: stripMetadataElement(data[key]) }),
      {} as T
    );
