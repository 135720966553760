import { AuthCallback, MessageType } from '@/auth/types';

const authCallbacks: Record<number, AuthCallback> = {};
let lastId = 0;

export const registerAuthCallback = (callback: AuthCallback): number => {
  lastId += 1;
  authCallbacks[lastId] = callback;
  return lastId;
};

export const deregisterAuthCallback = (id: number): void => {
  delete authCallbacks[id];
};

export const notify = async (type: MessageType, message?: string): Promise<void> => {
  await Promise.all(Object.values(authCallbacks).map(async (callback) => {
    await callback(type, message || '');
  }));
};

export const notifyError = async (error: unknown): Promise<void> => {
  const message = (process.env.NODE_ENV === 'production') ? 'An error occurred' : `${error}`;
  await Promise.all(Object.values(authCallbacks).map(async (callback) => {
    await callback('error', message);
  }));
};
