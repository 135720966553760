
  import Vue from 'vue';
  import { None } from '@/util/types';

  interface Data {
    display: boolean;
    resolve?: (response: boolean) => void;
  }

  interface Methods {
    getUserResponse(): Promise<boolean>;
  }

  export default Vue.extend<Data, Methods, None>({
    name: 'ConfirmationDialog',
    data() {
      return {
        display: false,
        resolve: undefined as unknown as (response: boolean) => void
      };
    },
    methods: {
      async getUserResponse() {
        this.display = true;
        return new Promise<boolean>((resolve) => {
          this.resolve = (value) => {
            resolve(value);
            this.display = false;
          };
        });
      }
    }
  });
