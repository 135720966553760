
  import Vue from 'vue';
  import { MetaInfo } from 'vue-meta';
  import { createSearchRoutingProperties, SearchRoutingProperties } from '@/util/searchRouter';
  import { retrieveSearchViewType, storeSearchViewType, ViewType } from '@/localStorage/searchView';
  import SearchBox from '@/components/search/SearchBox.vue';
  import SearchResults from '@/components/search/SearchResults.vue';
  import ViewTypeToggle from '@/components/search/ViewTypeToggle.vue';
  import RefinementList from '@/components/search/RefinementList.vue';
  import { getSearchClient, getSearchIndexName } from '@/services/algolia';
  import { formatNumber } from '@/util/formatting';
  import { None } from '@/util/types';

  interface Data {
    searchClient: unknown;
    indexName: string;
    routing: SearchRoutingProperties;
    viewType: ViewType;
  }

  interface Methods {
    formatNumber: typeof formatNumber;
  }

  export default Vue.extend<Data, Methods, None>({
    name: 'Search',
    data() {
      return {
        searchClient: getSearchClient(),
        indexName: getSearchIndexName(),
        routing: createSearchRoutingProperties(this.$router, getSearchIndexName()),
        viewType: retrieveSearchViewType()
      };
    },
    methods: {
      formatNumber
    },
    watch: {
      viewType(value) {
        storeSearchViewType(value);
      }
    },
    components: {
      ViewTypeToggle,
      SearchBox,
      SearchResults,
      RefinementList
    },
    metaInfo(): MetaInfo {
      return {
        title: 'Search',
        meta: [
          {
            name: 'og:title',
            content: 'Search'
          },
          {
            name: 'og:type',
            content: 'website'
          },
          {
            name: 'og:image',
            content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
          },
          {
            name: 'og:url',
            content: window.location.href
          },
          {
            name: 'og:author',
            content: 'WANMA'
          },
          {
            name: 'og:description',
            content: 'Search the WANMA Collection.'
          }
        ]
      };
    }
  });
