
  import Vue from 'vue';
  import { MetaInfo } from 'vue-meta';
  import { None } from '@/util/types';

  interface Computed {
    emailAddressHref: string;
  }

  export default Vue.extend<None, None, Computed>({
    name: 'About',
    computed: {
      emailAddressHref() {
        return `${'mai'}${'lto:wan'}${'ma@sl'}${'wa.wa.g'}${'ov.au'}`;
      }
    },
    metaInfo(): MetaInfo {
      return {
        title: 'About WANMA',
        meta: [
          {
            name: 'og:title',
            content: 'About WANMA'
          },
          {
            name: 'og:type',
            content: 'website'
          },
          {
            name: 'og:image',
            content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
          },
          {
            name: 'og:url',
            content: window.location.href
          },
          {
            name: 'og:author',
            content: 'WANMA'
          },
          {
            name: 'og:description',
            content: 'The Western Australian New Music Archive (WANMA) is an Australian Research Council supported '
              + 'Linkage project that aims to develop and establish a digital repository and interface of new music by '
              + 'Western Australian composers from 1970 to the current day.'
          }
        ]
      };
    }
  });
