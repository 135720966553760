
  import Vue from 'vue';
  import { getEntityTypes } from '@/util/entityTypes';

  export default Vue.extend({
    name: 'Footer',
    computed: {
      wanma() {
        return this.$vuetify.breakpoint.smAndDown ? 'WANMA' : 'Western Australian New Music Archive';
      },
      entityTypes() {
        return getEntityTypes();
      }
    }
  });
