
  import Vue from 'vue';
  import EntityResultTags from '@/components/search/EntityResultTags.vue';
  import { Entity } from '@/api-schema';
  import { None } from '@/util/types';
  import { ViewType } from '@/localStorage/searchView';

  interface Props {
    item: Entity;
    viewType: ViewType;
    hideTags?: boolean;
    plain?: boolean;
  }

  interface Computed {
    cardClass: string;
    imageWidth: string;
    imageClass: string;
    titleClass: string;
    textClass: string;
  }

  export default Vue.extend<None, None, Computed, Props>({
    name: 'EntityResult',
    props: {
      item: {
        type: Object as () => Entity,
        required: true
      },
      viewType: {
        type: String as () => ViewType,
        required: true
      },
      hideTags: {
        type: Boolean,
        required: false
      },
      plain: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      cardClass() {
        return `d-flex transparent ${this.viewType === 'list' ? '' : 'flex-column'}`;
      },
      imageWidth() {
        return this.viewType === 'list' ? '120px' : 'auto';
      },
      imageClass() {
        return this.viewType === 'list' ? 'ma-3 rounded flex-grow-0' : 'flex-grow-0';
      },
      titleClass() {
        return (this.viewType === 'list' && this.$vuetify.breakpoint.lgAndUp) || this.$vuetify.breakpoint.xl
          ? 'text-h5'
          : 'text-h6';
      },
      textClass() {
        return this.$vuetify.breakpoint.lgAndUp ? 'text-body-1 mt-3' : 'text-body-2 mt-3';
      }
    },
    components: {
      EntityResultTags
    }
  });
