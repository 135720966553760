
  import Vue from 'vue';
  import { Entity, Label, Path, TypePath } from '@/api-schema';
  import { getEntityTypes, isMediaType } from '@/util/entityTypes';
  import { getSlug } from '@/util/urls';

  type RelatedEntityView = Pick<Entity, 'path' | 'type' | 'label'>;

  interface Data {
    displayDialog: boolean;
    targetType?: TypePath;
    targetSlug?: Path;
    targetLabel?: Label;
    targetSlugLocked: boolean;
    addToRelated: boolean;
    openInNewWindow: boolean;
  }

  interface Methods {
    confirmCreate(): Promise<void>;
  }

  interface Computed {
    articleEntityTypePaths: TypePath[];
    resultingUrl: Path;
    labelToSave: Label;
    hasEmptyFields: boolean;
  }

  interface Props {
    currentEntityLabel: Label;
    related: RelatedEntityView[];
    createEntity(type: TypePath, slug: Path, label: Label): Promise<Path | undefined>;
  }

  export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'CreateEntity',
    model: {
      prop: 'related',
      event: 'input'
    },
    props: {
      currentEntityLabel: {
        type: String,
        required: true
      },
      related: {
        type: Array as () => RelatedEntityView[],
        required: true
      },
      createEntity: {
        type: Function as unknown as () => (type: TypePath, slug: Path, label: Label) => Promise<Path | undefined>,
        required: true
      }
    },
    data() {
      return {
        displayDialog: false,
        targetType: undefined as TypePath | undefined,
        targetSlug: undefined as Path | undefined,
        targetLabel: undefined as Label | undefined,
        targetSlugLocked: false,
        addToRelated: false,
        openInNewWindow: true
      };
    },
    methods: {
      async confirmCreate() {
        if (this.hasEmptyFields) {
          return;
        }
        const createdPath = await this.createEntity(
          this.targetType as TypePath,
          this.targetSlug as Path,
          this.labelToSave as Label
        );
        if (!createdPath) {
          return;
        }
        if (this.addToRelated && !this.related.find(({ path }) => path === createdPath)) {
          this.related.push({
            path: createdPath as Path,
            type: this.targetType as TypePath,
            label: this.labelToSave as Label
          });
          this.$emit('input:related', this.related);
        }
        this.targetType = undefined;
        this.targetSlug = '';
        this.targetLabel = '';
        this.displayDialog = false;
        if (this.openInNewWindow) {
          window.open(`${window.location.protocol}//${window.location.host}${createdPath}`);
        }
      }
    },
    computed: {
      articleEntityTypePaths() {
        return getEntityTypes()
          .map(({ path }) => path.substring(1) as TypePath)
          .filter((type) => !isMediaType(type));
      },
      resultingUrl() {
        if (!this.targetType || !this.targetSlug) {
          return '';
        }
        return `/${this.targetType}/${this.targetSlug}`;
      },
      labelToSave() {
        return this.targetLabel?.trim() || '';
      },
      hasEmptyFields() {
        return !this.targetType || !this.targetSlug || !this.labelToSave;
      }
    },
    watch: {
      displayDialog(value) {
        if (!value) {
          return;
        }
        this.targetType = undefined;
        this.targetSlug = '';
        this.targetLabel = '';
        this.targetSlugLocked = false;
      },
      targetLabel(value) {
        if (!this.targetSlugLocked) {
          this.targetSlug = getSlug(value);
        }
      }
    }
  });
