
  import Vue from 'vue';
  import { Entity, ProposedEntityRevision } from '@/api-schema';
  import { listEntitiesWithProposedRevisions } from '@/services/api/listEntitiesWithProposedRevisions';
  import { DataTableHeader } from '@/util/types';
  import { formatDate } from '@/util/formatting';
  import { getEntityType } from '@/util/entityTypes';

  interface Data {
    entities: Entity[];
    activity: boolean;
    errorMessage: string | undefined;
  }

  interface TableEntry {
    entity: Entity;
    revision: ProposedEntityRevision;
  }

  interface Computed {
    tableData: TableEntry[];
    headers: DataTableHeader[];
  }

  interface Methods {
    loadData: () => Promise<void>;
    formatDate: typeof formatDate;
    getEntityType: typeof getEntityType;
  }

  export default Vue.extend<Data, Methods, Computed>({
    name: 'ProposedRevisions',
    data() {
      return {
        entities: [] as Entity[],
        activity: false,
        errorMessage: undefined as string | undefined
      };
    },
    computed: {
      tableData() {
        return this.entities.reduce<TableEntry[]>(
          (result, entity) => [
            ...result,
            ...(entity.proposedRevisions ?? []).map((revision) => ({
              entity,
              revision
            }))
          ],
          []
        );
      },
      headers() {
        return [
          {
            text: 'Entity',
            value: 'entity.label',
            sortable: true
          },
          {
            text: 'Type',
            value: 'entity.type',
            sortable: true
          },
          {
            text: 'Proposed Revision By',
            value: 'revision.lastModified.by',
            sortable: true
          },
          {
            text: 'Proposed Revision At',
            value: 'revision.lastModified.at',
            sortable: true
          }
        ];
      }
    },
    methods: {
      async loadData() {
        this.activity = true;
        try {
          this.entities = await listEntitiesWithProposedRevisions(this.$apolloProvider);
        } catch (e) {
          this.errorMessage = (e as Error).message;
        } finally {
          this.activity = false;
        }
      },
      formatDate,
      getEntityType
    },
    async mounted() {
      await this.loadData();
    }
  });
