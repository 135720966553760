import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';

// Client that uses AWS API key based authorization. This is used for reading entities when not signed in.
export const createUnauthenticatedClient = (uri: string, key: string): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    name: 'unauthenticated',
    link: new HttpLink({
      uri,
      headers: { 'x-api-key': key }
    }),
    cache: new InMemoryCache()
  });
