
  import Vue from 'vue';
  import { None } from '@/util/types';

  interface Computed {
    publishTooltip: string;
    publishIcon: string;
  }

  interface Props {
    value: boolean;
    disabled?: boolean;
    togglePublished(): Promise<void>;
  }

  export default Vue.extend<None, None, Computed, Props>({
    name: 'PublishedToggleSwitch',
    props: {
      value: {
        type: Boolean,
        required: true
      },
      disabled: {
        type: Boolean,
        required: false
      },
      togglePublished: {
        type: Function as unknown as () => () => Promise<void>,
        required: true
      }
    },
    computed: {
      publishTooltip() {
        return this.value ? 'Published - click to revert to draft' : 'Draft - click to publish';
      },
      publishIcon() {
        return this.value ? 'mdi-publish-off' : 'mdi-publish';
      }
    }
  });
