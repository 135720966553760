import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Entity, GetEntityByPathInput, TypePath } from '@/api-schema';
import { articleFields, commonFields, mediaFields } from '@/services/api/helpers/fields';
import { deepCopy } from '@/util/deep';
import { isMediaType } from '@/util/entityTypes';
import { stripMetadata } from '@/services/api/helpers/metadata';

const retrieveMediaEntityQuery = gql`
  query RetrieveMediaEntity($input: GetEntityByPathInput!) {
    entity: getEntityByPath(input: $input) {
      ${commonFields}
      ${mediaFields}
    }
  }
`;

const retrieveArticleEntityQuery = gql`
  query RetrieveArticleEntity($input: GetEntityByPathInput!) {
    entity: getEntityByPath(input: $input) {
      ${commonFields}
      ${articleFields}
    }
  }
`;

export const retrieveEntity = async (
  apolloProvider: ApolloProvider,
  input: GetEntityByPathInput,
  user: CognitoUser | null,
  type: TypePath
): Promise<Entity> => {
  const apolloClient = user ? apolloProvider.clients.authenticated : apolloProvider.clients.unauthenticated;
  const result = await apolloClient.query({
    query: isMediaType(type) ? retrieveMediaEntityQuery : retrieveArticleEntityQuery,
    variables: { input }
  });
  return deepCopy(stripMetadata(result.data.entity));
};
