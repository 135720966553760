import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { RequestImportInput } from '@/api-schema';

const requestImportMutation = gql`
  mutation RequestImport($input: RequestImportInput!) {
    message: requestImport(input: $input)
  }
`;

export const requestImport = async (
  apolloProvider: ApolloProvider,
  input: RequestImportInput
): Promise<string> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: requestImportMutation,
    variables: { input }
  });
  return result.data.message;
};
