
  import Vue from 'vue';
  import { None } from '@/util/types';
  import { Path, ProposedEntityRevision } from '@/api-schema';
  import { belongsToGroup } from '@/auth/cognito';

  interface SelectItem {
    text: string;
    value: string;
    revision?: ProposedEntityRevision;
  }

  interface Data {
    selectValue: string;
  }

  interface Computed {
    items: Array<SelectItem>;
  }

  interface Props {
    path: Path;
    value: ProposedEntityRevision;
    proposedRevisions: ProposedEntityRevision[];
  }

  export default Vue.extend<Data, None, Computed, Props>({
    props: {
      path: {
        type: String as () => Path,
        required: true
      },
      value: {
        type: Object as () => ProposedEntityRevision,
        required: false
      },
      proposedRevisions: {
        type: Array as () => ProposedEntityRevision[],
        required: true
      }
    },
    data() {
      return {
        selectValue: this.value?.username ?? ''
      };
    },
    computed: {
      items() {
        return [
          {
            text: 'Main article',
            value: '',
            revision: undefined
          },
          ...this.proposedRevisions.map((revision) => {
            const { lastModified: { by }, username } = revision;
            return {
              text: belongsToGroup('curators') ? `Proposed revision by ${by}` : 'Your proposed revision',
              value: username,
              revision
            };
          })
        ];
      }
    },
    watch: {
      selectValue(value) {
        this.$emit('input', this.proposedRevisions.find(({ username }) => username === value));
        this.$router.push(value ? `${this.path}/${value}` : this.path);
      }
    }
  });
