
  import Vue from 'vue';
  import { Hit, UiState } from 'instantsearch.js';
  import { Path, TypePath } from '@/api-schema';
  import { getSearchClient, getSearchIndexName } from '@/services/algolia';
  import { getPath } from '@/util/urls';

  interface Data {
    displayDialog: boolean;
    searchClient: unknown;
    indexName: string;
    mergeTarget: Hit | undefined;
    mergeTargetSearch: string;
  }

  interface Methods {
    confirmMerge(): Promise<void>;
  }

  interface Computed {
    mergeSearchUiState: UiState;
    path: Path;
  }

  interface Props {
    type: TypePath;
    slug: Path;
    label: Path;
    disabled?: boolean;
    mergeEntities(target: Path): Promise<void>;
  }

  export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'MergeEntities',
    props: {
      type: {
        type: String as () => TypePath,
        required: true
      },
      slug: {
        type: String as () => Path,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        required: false
      },
      mergeEntities: {
        type: Function as unknown as () => (target: Path) => Promise<void>,
        required: true
      }
    },
    data() {
      return {
        displayDialog: false,
        searchClient: getSearchClient(),
        indexName: getSearchIndexName(),
        mergeTarget: undefined as Hit | undefined,
        mergeTargetSearch: ''
      };
    },
    methods: {
      async confirmMerge() {
        if (!this.mergeTarget) {
          return;
        }
        await this.mergeEntities(this.mergeTarget.path);
        this.displayDialog = false;
      }
    },
    computed: {
      mergeSearchUiState(): UiState {
        return {
          [this.indexName]: {
            refinementList: {
              type: [this.type]
            },
            hitsPerPage: 5
          }
        };
      },
      path() {
        return getPath(this.type, this.slug);
      }
    }
  });
