
  import Vue from 'vue';
  import { MediaSource, MediaTypePath, URL } from '@/api-schema';
  import { None } from '@/util/types';

  interface Props {
    type: MediaTypePath;
    activity?: boolean;
    avatarUrl?: URL;
    mediaSources?: MediaSource[];
  }

  interface Computed {
    displayImageViewer: boolean;
    displayAudioVideoViewer: boolean;
  }

  export default Vue.extend<None, None, Computed, Props>({
    name: 'MediaViewer',
    props: {
      type: {
        type: String as () => MediaTypePath,
        required: true
      },
      activity: {
        type: Boolean,
        required: false
      },
      avatarUrl: {
        type: String as () => URL | undefined,
        required: false
      },
      mediaSources: {
        type: Array as () => MediaSource[] | undefined,
        required: false
      }
    },
    computed: {
      displayImageViewer() {
        return !!this.avatarUrl && (this.type === 'photographs' || this.type === 'documents');
      },
      displayAudioVideoViewer() {
        return !!this.avatarUrl && (this.type === 'video' || this.type === 'audio');
      }
    }
  });
