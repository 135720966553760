
  import Vue from 'vue';
  import { Tag } from '@/api-schema';
  import TagChip from '@/components/TagChip.vue';
  import { getSearchIndex } from '@/services/algolia';
  import { None } from '@/util/types';

  interface Data {
    currentValue?: Tag[];
    previousValue?: Tag[];
    searchList: Tag[];
    searchText?: string;
    errorMessage?: string;
  }

  interface Methods {
    acceptTag(): void;
    removeTag(tag: string): void;
  }

  interface Props {
    value?: Tag[];
    editable?: boolean;
    activity?: boolean;
  }

  export default Vue.extend<Data, Methods, None, Props>({
    name: 'Tags',
    props: {
      value: {
        type: Array as () => string[],
        required: false
      },
      editable: {
        type: Boolean,
        required: false
      },
      activity: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        currentValue: this.value,
        previousValue: this.value,
        searchList: this.value ?? [],
        searchText: undefined as string | undefined,
        errorMessage: undefined as string | undefined
      };
    },
    methods: {
      acceptTag() {
        this.searchText = '';
        this.$emit('input', this.currentValue);
      },
      removeTag(tag) {
        if (!this.currentValue) {
          return;
        }
        const index = this.currentValue.indexOf(tag);
        if (index >= 0) {
          this.currentValue.splice(index, 1);
        }
      }
    },
    watch: {
      async searchText(inputValue) {
        const { facetHits } = await getSearchIndex().searchForFacetValues('tags', inputValue);
        this.searchList = [
          ...(this.currentValue ?? []),
          ...facetHits.map(({ value }) => value)
        ];
      },
      value() {
        this.currentValue = this.value ?? [];
        this.previousValue = this.value ?? [];
      }
    },
    components: { TagChip }
  });
