import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { DeleteEntityInput } from '@/api-schema';

const deleteEntityMutation = gql`
  mutation DeleteEntity($input: DeleteEntityInput!) {
    deletedPath: deleteEntity(input: $input)
  }
`;

export const deleteEntity = async (
  apolloProvider: ApolloProvider,
  input: DeleteEntityInput
): Promise<string> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: deleteEntityMutation,
    variables: { input }
  });
  return result.data.deletedPath;
};
