
  import Vue from 'vue';
  import { None } from '@/util/types';
  import { ViewType } from '@/localStorage/searchView';

  interface Data {
    localValue: ViewType;
  }

  interface Methods {
    emitUpdateEvent($event: string): void;
  }

  interface Props {
    value: ViewType;
  }

  export default Vue.extend<Data, Methods, None, Props>({
    name: 'ViewTypeToggle',
    props: {
      value: {
        type: String as () => ViewType,
        required: true
      }
    },
    data() {
      return {
        localValue: this.value
      };
    },
    methods: {
      emitUpdateEvent($event: string) {
        this.$emit('input', $event);
      }
    }
  });
