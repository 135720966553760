
  import Vue from 'vue';
  import { None } from '@/util/types';

  interface Data {
    displayDialog: boolean;
  }

  interface Props {
    disabled?: boolean;
    deleteEntity(): Promise<void>;
  }

  export default Vue.extend<Data, None, None, Props>({
    name: 'DeleteEntity',
    props: {
      disabled: {
        type: Boolean,
        required: false
      },
      deleteEntity: {
        type: Function as unknown as () => () => Promise<void>,
        required: true
      }
    },
    data() {
      return {
        displayDialog: false
      };
    }
  });
