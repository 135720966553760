
  import Vue from 'vue';
  import { MetaInfo } from 'vue-meta';

  interface Data {
    username: string;
    confirmationCode: string;
  }

  interface Methods {
    confirmSignup(evt: Event): Promise<void>;
    redirectIfLoggedIn(): void;
  }

  interface Computed {
    loginPath: string;
  }

  export default Vue.extend<Data, Methods, Computed>({
    name: 'SignupConfirmation',
    data() {
      return {
        username: `${this.$route.query.email || ''}`,
        confirmationCode: ''
      };
    },
    methods: {
      async confirmSignup(evt: Event): Promise<void> {
        evt.preventDefault();
        try {
          await this.$auth.confirmSignup(this.username, this.confirmationCode);
          await this.$router.push(this.loginPath);
        } catch (e) {
          // Ignore error, it is displayed as a Snackbar, see AuthNotifications component
        }
      },
      redirectIfLoggedIn() {
        if (this.$user.cognitoUser) {
          this.$router.push(this.$auth.getRouteReturnPath(this.$router.currentRoute));
        }
      }
    },
    computed: {
      loginPath() {
        return this.$auth.getLoginPath(this.$route, this.$data.username);
      }
    },
    beforeMount() {
      this.redirectIfLoggedIn();
    },
    authEvent() {
      this.redirectIfLoggedIn();
    },
    metaInfo(): MetaInfo {
      return {
        title: 'Create an account at WANMA',
        meta: [
          {
            name: 'og:title',
            content: 'Create an account at WANMA'
          },
          {
            name: 'og:type',
            content: 'website'
          },
          {
            name: 'og:image',
            content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
          },
          {
            name: 'og:url',
            content: window.location.href
          },
          {
            name: 'og:author',
            content: 'WANMA'
          },
          {
            name: 'og:description',
            content: 'The Western Australian New Music Archive (WANMA) is an Australian Research Council supported '
              + 'Linkage project that aims to develop and establish a digital repository and interface of new music by '
              + 'Western Australian composers from 1970 to the current day.'
          }
        ]
      };
    }
  });
