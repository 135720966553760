import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { Entity, MoveEntityInput } from '@/api-schema';
import { deepCopy } from '@/util/deep';
import { stripMetadata } from '@/services/api/helpers/metadata';

const moveEntityMutation = gql`
  mutation MoveEntity($input: MoveEntityInput!) {
    entity: moveEntity(input: $input) {
      path
      label
      redirect
    }
  }
`;

export const moveEntity = async (
  apolloProvider: ApolloProvider,
  input: MoveEntityInput
): Promise<Pick<Entity, 'path' | 'label' | 'redirect'>> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: moveEntityMutation,
    variables: { input }
  });
  return deepCopy(stripMetadata(result.data.entity));
};
