
  import Vue from 'vue';
  import { MetaInfo } from 'vue-meta';
  import {
    Entity,
    EntityUpdates,
    Label,
    MediaTypePath,
    Path,
    ProposedEntityRevision,
    ProposedRevisionAction,
    TypePath
  } from '@/api-schema';
  import { retrieveEntity } from '@/services/api/retrieveEntity';
  import { editEntity } from '@/services/api/editEntity';
  import { deleteEntity } from '@/services/api/deleteEntity';
  import { createEntity } from '@/services/api/createEntity';
  import { moveEntity } from '@/services/api/moveEntity';
  import { mergeEntities } from '@/services/api/mergeEntities';
  import { isMediaType } from '@/util/entityTypes';
  import { retrieveEditableMode, storeEditableMode } from '@/localStorage/editable';
  import { deepCopy, deepEquals } from '@/util/deep';
  import NotFound from '@/views/NotFound.vue';
  import RedirectGuard from '@/components/RedirectGuard.vue';
  import PassiveMessage from '@/components/PassiveMessage.vue';
  import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
  import Heading from '@/components/entity/Heading.vue';
  import Tags from '@/components/entity/Tags.vue';
  import Attribute from '@/components/entity/Attribute.vue';
  import AttributeCard from '@/components/entity/AttributeCard.vue';
  import Avatar from '@/components/entity/Avatar.vue';
  import MediaViewer from '@/components/entity/MediaViewer.vue';
  import WebAndSocialsCard from '@/components/entity/WebAndSocialsCard.vue';
  import RelatedEntitiesCard from '@/components/entity/RelatedEntitiesCard.vue';
  import LastModified from '@/components/entity/LastModified.vue';
  import Provenance from '@/components/entity/Provenance.vue';
  import DeleteEntity from '@/components/entity/controls/DeleteEntity.vue';
  import MoveEntity from '@/components/entity/controls/MoveEntity.vue';
  import MergeEntities from '@/components/entity/controls/MergeEntities.vue';
  import EditModeSwitch from '@/components/entity/controls/EditModeSwitch.vue';
  import PublishedToggleSwitch from '@/components/entity/controls/PublishedToggleSwitch.vue';
  import SaveAndRevert from '@/components/entity/controls/SaveAndRevert.vue';
  import CreateEntity from '@/components/entity/controls/CreateEntity.vue';
  import ProposedRevisionSelector from '@/components/entity/controls/ProposedRevisionSelector.vue';
  import ApproveAndReject from '@/components/entity/controls/ApproveAndReject.vue';
  import { processProposedRevision } from '@/services/api/processProposedRevision';
  import { belongsToGroup } from '@/auth/cognito';
  import { GroupName } from '@/auth/types';
  import { getCanonicalEntityPath, getTypePath } from '@/util/urls';
  import HowToEditThisPage from '@/components/entity/HowToEditThisPage.vue';

  interface Data {
    path: Path;
    entity: Entity;
    loadedEntity: Entity;
    cleanEntity: Entity;
    proposedRevision: ProposedEntityRevision | undefined;
    editable: boolean;
    activity: boolean;
    errorMessage?: string;
    createdEntities: Array<Pick<Entity, 'path' | 'label'>>;
    entityCreatedMessage?: string;
  }

  interface Methods {
    retrieveEntity(): Promise<void>;
    togglePublished(): Promise<void>;
    saveChanges(): Promise<void>;
    revertChanges(): void;
    moveEntity(type: TypePath, slug: Path, label: Label): Promise<void>;
    mergeEntities(target: Path): Promise<void>;
    createEntity(type: TypePath, slug: Path, label: Label): Promise<Path | undefined>;
    deleteEntity(): Promise<void>;
    processProposedRevision(action: ProposedRevisionAction): Promise<void>;
    confirmUnsavedChanges(): Promise<boolean>;
    beforeUnload(evt: Event): Promise<string | undefined>;
    belongsToGroup(group: GroupName): boolean;
  }

  interface Computed {
    isMediaEntity: boolean;
    isMediaEntityWithEditableAvatar: boolean;
    mediaEntityType: MediaTypePath;
    displayControls: boolean;
    modifiedKeys: Array<keyof Entity>;
    isModified: boolean;
    hasProposedRevisions: boolean;
  }

  export default Vue.extend<Data, Methods, Computed>({
    name: 'Entity',
    data() {
      return {
        path: getCanonicalEntityPath(this.$route.path),
        entity: {} as unknown as Entity,
        loadedEntity: {} as unknown as Entity,
        cleanEntity: {} as unknown as Entity,
        proposedRevision: undefined as ProposedEntityRevision | undefined,
        editable: retrieveEditableMode(),
        activity: false,
        errorMessage: undefined as undefined | string,
        createdEntities: [] as Array<Pick<Entity, 'path' | 'label'>>,
        entityCreatedMessage: undefined as undefined | string
      };
    },
    methods: {
      async retrieveEntity() {
        this.activity = true;
        try {
          this.loadedEntity = await retrieveEntity(
            this.$apolloProvider,
            {
              path: this.path
            },
            this.$user.cognitoUser,
            getTypePath(this.path)
          );
        } catch (e) {
          this.errorMessage = e.message;
        } finally {
          this.activity = false;
        }
      },
      async togglePublished() {
        if (!this.entity) {
          return;
        }
        this.activity = true;
        this.errorMessage = undefined;
        try {
          this.loadedEntity = await editEntity(
            this.$apolloProvider,
            {
              path: this.path,
              updates: { published: !this.entity.published }
            },
            this.entity.type
          );
        } catch (e) {
          this.errorMessage = e.message ?? `${e}`;
        } finally {
          this.activity = false;
        }
      },
      async saveChanges() {
        if (!this.entity || this.modifiedKeys.length === 0) {
          return;
        }
        this.activity = true;
        this.errorMessage = undefined;
        try {
          this.loadedEntity = await editEntity(
            this.$apolloProvider,
            {
              path: this.path,
              updates: this.modifiedKeys.reduce<EntityUpdates>(
                (result, field) => ({
                  ...result,
                  [field]: field === 'related'
                    ? this.entity.related?.map<Path>(({ path }) => path)
                    : this.entity[field]
                }),
                {} as Partial<EntityUpdates>
              )
            },
            this.entity.type
          );
        } catch (e) {
          this.errorMessage = e.message ?? `${e}`;
        } finally {
          this.activity = false;
        }
      },
      revertChanges() {
        if (this.proposedRevision) {
          const nonNullProposedUpdates = Object.entries(this.proposedRevision)
            .filter((entry) => entry[0] !== 'username')
            .filter((entry) => entry[1] !== null && entry[1] !== undefined)
            .reduce((result, [key, proposedValue]) => ({ ...result, [key]: proposedValue }), {});
          this.cleanEntity = deepCopy<Entity>({ ...this.loadedEntity, ...nonNullProposedUpdates });
        } else {
          this.cleanEntity = deepCopy(this.loadedEntity);
        }
      },
      async moveEntity(type: TypePath, slug: Path, label: Label) {
        if (!this.entity) {
          return;
        }
        this.activity = true;
        this.errorMessage = undefined;
        try {
          const result = await moveEntity(
            this.$apolloProvider,
            {
              path: this.path,
              target: { type, slug, label }
            }
          );
          if (result.redirect) {
            await this.$router.push(result.redirect);
          }
        } catch (e) {
          this.errorMessage = e.message ?? `${e}`;
        } finally {
          this.activity = false;
        }
      },
      async mergeEntities(target: Path) {
        if (!this.entity) {
          return;
        }
        this.activity = true;
        this.errorMessage = undefined;
        try {
          const result = await mergeEntities(
            this.$apolloProvider,
            {
              path: this.path,
              target
            }
          );
          if (result.redirect) {
            await this.$router.push(result.redirect);
          }
        } catch (e) {
          this.errorMessage = e.message ?? `${e}`;
        } finally {
          this.activity = false;
        }
      },
      async createEntity(type: TypePath, slug: Path, label: Label) {
        this.activity = true;
        this.errorMessage = undefined;
        try {
          return await createEntity(
            this.$apolloProvider,
            {
              type,
              slug,
              label
            }
          );
        } catch (e) {
          this.errorMessage = e.message ?? `${e}`;
          return undefined;
        } finally {
          this.activity = false;
        }
      },
      async deleteEntity() {
        if (!this.entity) {
          return;
        }
        this.activity = true;
        this.errorMessage = undefined;
        try {
          await deleteEntity(
            this.$apolloProvider,
            {
              path: this.path
            }
          );
          await this.$router.push('/');
        } catch (e) {
          this.errorMessage = e.message ?? `${e}`;
        } finally {
          this.activity = false;
        }
      },
      async processProposedRevision(action: ProposedRevisionAction) {
        if (!this.entity || !this.proposedRevision) {
          return;
        }
        this.activity = true;
        this.errorMessage = undefined;
        try {
          this.loadedEntity = await processProposedRevision(
            this.$apolloProvider,
            {
              path: this.path,
              username: this.proposedRevision.username,
              action
            },
            this.entity.type
          );
        } catch (e) {
          this.errorMessage = e.message ?? `${e}`;
        } finally {
          this.activity = false;
        }
      },
      // These two methods serve similar but different purposes. The confirmUnsavedChanges method is for navigation
      // *within* the application (i.e. via the router / push state). The beforeUnload method exists to protect against
      // the user *closing or reloading* the browser window (which is outside the scope of the Vue application).
      // (https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event).
      async confirmUnsavedChanges() {
        const dialog = this.$refs.confirmationDialog as unknown as { getUserResponse: () => Promise<boolean> };
        return this.modifiedKeys.length === 0 || !dialog || dialog.getUserResponse();
      },
      async beforeUnload(evt: BeforeUnloadEvent) {
        if (this.modifiedKeys.length > 0) {
          evt.preventDefault();
          // The following is for browser compatibility. The message itself does not get displayed in modern browsers.
          // See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
          /* eslint-disable-next-line no-param-reassign */
          evt.returnValue = 'You have unsaved changes. Are you sure you want to exit?';
        }
        return evt.returnValue;
      },
      belongsToGroup
    },
    computed: {
      isMediaEntity() {
        return isMediaType(this.$route.params.type as TypePath);
      },
      isMediaEntityWithEditableAvatar() {
        return ['video', 'audio'].includes(this.$route.params.type);
      },
      mediaEntityType() {
        return this.$route.params.type as MediaTypePath;
      },
      displayControls() {
        return !!this.entity?.path && !!this.$user.cognitoUser;
      },
      modifiedKeys() {
        return (Object.keys(this.cleanEntity) as Array<keyof Entity>)
          .filter((key) => key !== 'lastModified')
          .filter((key) => !deepEquals(this.entity[key], this.cleanEntity[key]));
      },
      isModified() {
        return this.modifiedKeys.length > 0;
      },
      hasProposedRevisions() {
        return (this.entity?.proposedRevisions ?? []).length > 0;
      }
    },
    watch: {
      editable(value: boolean) {
        if (!belongsToGroup('curators') && this.hasProposedRevisions) {
          [this.proposedRevision] = this.entity.proposedRevisions as ProposedEntityRevision[];
        }
        storeEditableMode(value);
      },
      loadedEntity(value: Entity) {
        const isCurator = belongsToGroup('curators');
        const revision = isCurator ? this.$route.params.revision : this.$user.attributes.userId;
        this.proposedRevision = (value.proposedRevisions ?? []).find(({ username }) => username === revision);
        this.revertChanges();
      },
      cleanEntity(value) {
        this.entity = deepCopy(value);
      },
      proposedRevision() {
        this.revertChanges();
      }
    },
    authEvent() {
      this.editable = retrieveEditableMode() && !!this.$user.cognitoUser;
    },
    beforeMount() {
      window.addEventListener('beforeunload', this.beforeUnload, { capture: true });
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeUnload, { capture: true });
    },
    async mounted() {
      await this.retrieveEntity();
    },
    async beforeRouteUpdate(to, from, next) {
      if (await this.confirmUnsavedChanges()) {
        this.revertChanges();
        this.path = getCanonicalEntityPath(to.path);
        // Calling next() before retrieveEntity() means that the layout can be based on the new entity's type.
        next();
        await this.retrieveEntity();
      }
    },
    async beforeRouteLeave(to, from, next) {
      if (await this.confirmUnsavedChanges()) {
        this.revertChanges();
        next();
      }
    },
    components: {
      HowToEditThisPage,
      ApproveAndReject,
      ProposedRevisionSelector,
      CreateEntity,
      SaveAndRevert,
      PublishedToggleSwitch,
      EditModeSwitch,
      MergeEntities,
      MoveEntity,
      DeleteEntity,
      NotFound,
      RedirectGuard,
      PassiveMessage,
      ConfirmationDialog,
      Heading,
      Tags,
      Attribute,
      AttributeCard,
      Avatar,
      MediaViewer,
      WebAndSocialsCard,
      RelatedEntitiesCard,
      Provenance,
      LastModified
    },
    metaInfo(): MetaInfo {
      return {
        title: this.entity?.label ?? '',
        meta: [
          {
            name: 'og:title',
            content: this.entity?.label ?? ''
          },
          {
            name: 'og:type',
            content: 'website'
          },
          {
            name: 'og:image',
            content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
          },
          {
            name: 'og:url',
            content: window.location.href
          },
          {
            name: 'og:author',
            content: 'WANMA'
          },
          {
            name: 'og:description',
            content: `An article about ${this.entity?.label}.`
          }
        ]
      };
    }
  });
