import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { CreateEntityInput, Path } from '@/api-schema';

const createEntityMutation = gql`
  mutation CreateEntity($input: CreateEntityInput!) {
    createdEntityPath: createEntity(input: $input) {
      path
    }
  }
`;

export const createEntity = async (
  apolloProvider: ApolloProvider,
  input: CreateEntityInput
): Promise<Path> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: createEntityMutation,
    variables: { input }
  });
  return result.data.createdEntityPath.path;
};
