import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { isMediaType } from '@/util/entityTypes';
import { articleFields, commonFields, mediaFields } from '@/services/api/helpers/fields';
import { Entity, ProcessProposedRevisionInput, TypePath } from '@/api-schema';
import { stripMetadata } from '@/services/api/helpers/metadata';
import { deepCopy } from '@/util/deep';

const processProposedMediaEntityMutation = gql`
  mutation ProcessProposedRevisionMediaEntity($input: ProcessProposedRevisionInput!) {
    entity: processProposedRevision(input: $input) {
      ${commonFields}
      ${mediaFields}
    }
  }
`;

const processProposedArticleEntityMutation = gql`
  mutation ProcessProposedRevisionArticleEntity($input: ProcessProposedRevisionInput!) {
    entity: processProposedRevision(input: $input) {
      ${commonFields}
      ${articleFields}
    }
  }
`;

export const processProposedRevision = async (
  apolloProvider: ApolloProvider,
  input: ProcessProposedRevisionInput,
  type: TypePath
): Promise<Entity> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: isMediaType(type) ? processProposedMediaEntityMutation : processProposedArticleEntityMutation,
    variables: { input }
  });
  return deepCopy(stripMetadata(result.data.entity));
};
