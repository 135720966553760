import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { ImportRequest } from '@/api-schema';

const listImportRequestsQuery = gql`
  query ListImportRequests {
    importRequests: listImportRequests {
      source
      name
      startDate
      stopDate
      status
    }
  }
`;

export const listImportRequests = async (
  apolloProvider: ApolloProvider
): Promise<ImportRequest[]> => {
  const result = await apolloProvider.defaultClient.query({
    query: listImportRequestsQuery,
    fetchPolicy: 'no-cache'
  });
  return result.data.importRequests;
};
