
  import Vue from 'vue';
  import { Provenance } from '@/api-schema';
  import { None } from '@/util/types';

  interface Props {
    provenance?: Provenance;
    activity?: boolean;
  }

  interface Computed {
    provenanceUrl?: string;
    provenanceLabel?: string;
  }

  export default Vue.extend<None, None, Computed, Props>({
    name: 'Provenance',
    props: {
      provenance: {
        type: Object as () => Provenance,
        required: false
      },
      activity: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      provenanceLabel() {
        if (!this.provenance) {
          return 'unknown';
        }
        return this.provenance.brn
          ? `${this.provenance.origin} - b${this.provenance.brn}`
          : this.provenance.origin;
      }
    }
  });
