
  import Vue from 'vue';
  import EntityResult from '@/components/search/EntityResult.vue';
  import { Entity } from '@/api-schema';
  import { None } from '@/util/types';
  import { ViewType } from '@/localStorage/searchView';

  interface Props {
    items: Entity[];
    viewType: ViewType
  }

  interface Computed {
    containerClass: string;
    containerOutlined: boolean;
    itemClass: string;
  }

  export default Vue.extend<None, None, Computed, Props>({
    name: 'SearchResults',
    props: {
      items: {
        type: Array as () => Entity[],
        required: true
      },
      viewType: {
        type: String as () => ViewType,
        required: true
      }
    },
    computed: {
      containerClass() {
        return `${this.viewType}-view`;
      },
      containerOutlined() {
        return this.viewType === 'list';
      },
      itemClass() {
        return this.viewType === 'list' ? 'col-12 ma-0 pa-0' : 'col-12 col-sm-6 col-md-4 col-xl-3';
      }
    },
    components: {
      EntityResult
    }
  });
