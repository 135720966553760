import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import Vue from 'vue';

const GA_MEASUREMENT_ID = process.env.VUE_APP_GA_MEASUREMENT_ID || '';

export function initGtagAnalytics(router: VueRouter): void {
  Vue.use(VueGtag, {
    config: {
      id: GA_MEASUREMENT_ID
    }
  }, router);
}
