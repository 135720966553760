
  import Vue from 'vue';
  import { Path, TypePath } from '@/api-schema';
  import { getAvatarUpload } from '@/services/api/getAvatarUpload';
  import { upload } from '@/util/upload';

  interface Data {
    fullscreenAvatar: boolean;
    showUploadDialog: boolean;
    fileToUpload?: File;
    uploadError?: string;
    uploading: boolean;
  }

  interface Methods {
    upload(evt: Event): void;
    toggleFullscreen(): void;
  }

  interface Computed {
    showEditor: boolean;
  }

  interface Props {
    value?: string;
    entityType?: TypePath;
    entitySlug?: Path;
    editable?: boolean;
    activity?: boolean;
  }

  export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'Avatar',
    props: {
      value: {
        type: String,
        required: false
      },
      entityType: {
        type: String as () => TypePath,
        required: false
      },
      entitySlug: {
        type: String as () => Path,
        required: false
      },
      editable: {
        type: Boolean,
        required: false
      },
      activity: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        fullscreenAvatar: false,
        showUploadDialog: false,
        fileToUpload: undefined as File | undefined,
        uploadError: undefined as string | undefined,
        uploading: false
      };
    },
    methods: {
      async upload(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.uploadError = undefined;
        if (!this.entityType || !this.entitySlug) {
          this.uploadError = 'No entity details available, please try again';
        } else if (!this.fileToUpload) {
          this.uploadError = 'No file selected';
        } else if (!/^image\//.test(this.fileToUpload.type)) {
          this.uploadError = 'File is not an image';
        } else {
          this.uploadError = undefined;
          this.uploading = true;
          try {
            const uploadInProgress = await getAvatarUpload(this.$apolloProvider, {
              entityType: this.entityType,
              entitySlug: this.entitySlug,
              contentType: this.fileToUpload.type
            });
            await upload(uploadInProgress.uploadUrl, this.fileToUpload);
            this.$emit('input', uploadInProgress.displayUrl);
          } catch (e) {
            this.uploadError = e.message || `${e}`;
          } finally {
            this.showUploadDialog = false;
            this.uploading = false;
          }
        }
      },
      toggleFullscreen() {
        if (!this.editable) {
          this.fullscreenAvatar = !this.fullscreenAvatar;
        }
      }
    },
    computed: {
      showEditor() {
        return !!this.$user.cognitoUser && !!this.editable;
      }
    },
    watch: {
      displayDialog(value) {
        if (!value) {
          return;
        }
        this.fileToUpload = undefined;
        this.uploadError = undefined;
        this.showUploadDialog = true;
      }
    }
  });
