import Vue from 'vue';
import VueApollo, { ApolloProvider } from 'vue-apollo';
import { createUnauthenticatedClient } from '@/plugins/apollo/unauthenticatedClient';
import { createAuthenticatedClient } from '@/plugins/apollo/authenticatedClient';

export const buildApolloProvider = (): ApolloProvider => {
  const uri = process.env.VUE_APP_API_BASE_URL as string;
  const key = process.env.VUE_APP_API_KEY as string;

  Vue.use(VueApollo);

  return new VueApollo({
    clients: {
      unauthenticated: createUnauthenticatedClient(uri, key),
      authenticated: createAuthenticatedClient(uri)
    },
    defaultClient: createAuthenticatedClient(uri)
  });
};
