
  import Vue from 'vue';
  import { MetaInfo } from 'vue-meta';

  interface Data {
    username: string;
    confirmationCode: string;
    password: string;
    passwordConfirm: string;
  }

  interface Methods {
    confirmRecoverPassword(evt: Event): Promise<void>;
    redirectIfLoggedIn(): void;
  }

  interface Computed {
    canSubmit: boolean;
    loginPath: string;
  }

  export default Vue.extend<Data, Methods, Computed>({
    name: 'RecoverPasswordConfirmation',
    data() {
      return {
        username: `${this.$route.query.email || ''}`,
        confirmationCode: '',
        password: '',
        passwordConfirm: ''
      };
    },
    methods: {
      async confirmRecoverPassword(evt: Event): Promise<void> {
        evt.preventDefault();
        if (!this.canSubmit) {
          return;
        }
        try {
          await this.$auth.confirmRecoverPassword(this.username, this.confirmationCode, this.password);
          await this.$router.push(this.loginPath);
        } catch (e) {
          // Ignore error, it is displayed as a Snackbar, see AuthNotifications component
        }
      },
      redirectIfLoggedIn() {
        if (this.$user.cognitoUser) {
          this.$router.push(this.$auth.getRouteReturnPath(this.$router.currentRoute));
        }
      }
    },
    computed: {
      canSubmit() {
        return !!this.$data.username
          && !!this.$data.password
          && (this.$data.password === this.$data.passwordConfirm);
      },
      loginPath() {
        return this.$auth.getLoginPath(this.$route, this.$data.username);
      }
    },
    beforeMount() {
      this.redirectIfLoggedIn();
    },
    authEvent() {
      this.redirectIfLoggedIn();
    },
    metaInfo(): MetaInfo {
      return {
        title: 'Reset your WANMA password',
        meta: [
          {
            name: 'og:title',
            content: 'Reset your WANMA password'
          },
          {
            name: 'og:type',
            content: 'website'
          },
          {
            name: 'og:image',
            content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
          },
          {
            name: 'og:url',
            content: window.location.href
          },
          {
            name: 'og:author',
            content: 'WANMA'
          },
          {
            name: 'og:description',
            content: 'The Western Australian New Music Archive (WANMA) is an Australian Research Council supported '
              + 'Linkage project that aims to develop and establish a digital repository and interface of new music by '
              + 'Western Australian composers from 1970 to the current day.'
          }
        ]
      };
    }
  });
