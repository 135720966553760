
  import Vue from 'vue';
  import { None } from '@/util/types';

  interface Methods {
    hide(): void;
  }

  interface Computed {
    color: string;
    icon: string;
    display: boolean;
  }

  interface Props {
    value?: string;
    error?: boolean;
  }

  export default Vue.extend<None, Methods, Computed, Props>({
    name: 'PassiveMessage',
    inheritAttrs: false,
    props: {
      value: {
        type: String,
        required: false
      },
      error: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      color() {
        return this.error ? 'error' : 'success';
      },
      icon() {
        return this.error ? 'mdi-alert-circle-outline' : 'mdi-alert-circle-check-outline';
      },
      display() {
        return !!this.value;
      }
    },
    methods: {
      hide() {
        this.$emit('input', undefined);
      }
    }
  });
