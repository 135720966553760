
  import Vue from 'vue';
  import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
  import { getEntityType } from '@/util/entityTypes';
  import { None } from '@/util/types';

  interface Props {
    title: string;
    items: RefinementListItem[];
    refine(value: string): void;
    searchForItems(query: string): void;
  }

  interface Data {
    timerId: number | undefined;
  }

  interface Methods {
    getItemClass(isRefined: boolean): string;
    getEntityTypeIcon(item: RefinementListItem): string | undefined;
    debouncedSearchForItems(value: string): void;
  }

  export default Vue.extend<Data, Methods, None, Props>({
    name: 'RefinementList',
    props: {
      title: {
        type: String,
        required: true
      },
      items: {
        type: Array as () => RefinementListItem[],
        required: true
      },
      refine: {
        type: Function as unknown as () => (value: string) => void,
        required: true
      },
      searchForItems: {
        type: Function as unknown as () => (query: string) => void,
        required: false
      }
    },
    data() {
      return {
        timerId: undefined as number | undefined
      };
    },
    methods: {
      getItemClass(isRefined: boolean) {
        const refinedClass = isRefined ? 'grey lighten-2 font-weight-bold' : '';
        return `py-0 px-1 mx-0 ${refinedClass}`;
      },
      getEntityTypeIcon(item: RefinementListItem) {
        const entityType = getEntityType(item.value);
        return entityType?.icon;
      },
      debouncedSearchForItems(query) {
        if (this.timerId) {
          clearTimeout(this.timerId);
        }
        this.timerId = window.setTimeout(() => {
          if (this.searchForItems) {
            this.searchForItems(query);
          }
        }, 600);
      }
    }
  });
