
  import Vue from 'vue';
  import { Entity, MediaTypePath, Path, TypePath } from '@/api-schema';
  import { getSearchIndex } from '@/services/algolia';
  import EntityResult from '@/components/search/EntityResult.vue';

  const colsMap: Record<string, Record<string, number>> = {
    true: {
      xs: 6,
      sm: 4,
      md: 12,
      lg: 6,
      xl: 4
    },
    false: {
      xs: 6,
      sm: 4,
      md: 4,
      lg: 3,
      xl: 2
    }
  };

  type RelatedViewType = 'articles' | MediaTypePath;

  interface Props {
    type: RelatedViewType;
    heading: string;
    value: Entity[];
    editable?: boolean;
    activity?: boolean;
    vertical?: boolean;
  }

  interface Data {
    adding: boolean;
    addingSearch: string;
    searchList: Entity[];
  }

  interface Methods {
    toggleAdding(): void;
    addRelated(item: Entity): void;
    removeRelated(evt: Event, path: Path): void;
  }

  interface Computed {
    shouldDisplay: boolean;
    entities: Entity[];
    entityTypes: TypePath[];
    itemCols: number;
  }

  export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'RelatedEntitiesCard',
    props: {
      type: {
        type: String as () => RelatedViewType,
        required: true
      },
      heading: {
        type: String,
        required: true
      },
      value: {
        type: Array as () => Entity[],
        required: false
      },
      editable: {
        type: Boolean,
        required: false
      },
      activity: {
        type: Boolean,
        required: false
      },
      vertical: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        adding: false,
        addingSearch: '',
        searchList: this.value ?? []
      };
    },
    methods: {
      toggleAdding() {
        this.adding = !this.adding;
      },
      addRelated(item) {
        this.addingSearch = '';
        if (!this.value || this.value.find(({ path }) => path === item.path)) {
          return;
        }
        this.value.push(item);
        this.$emit('input', this.value);
      },
      removeRelated(evt, pathToRemove) {
        evt.stopPropagation();
        evt.preventDefault();
        if (!this.value) {
          return;
        }
        const index = this.value.findIndex(({ path }) => path === pathToRemove);
        if (index >= 0) {
          this.value.splice(index, 1);
          this.$emit('input', this.value);
        }
        this.addingSearch = '';
      }
    },
    computed: {
      shouldDisplay() {
        return this.activity || this.editable || this.entities.length > 0;
      },
      entityTypes() {
        return this.type === 'articles'
          ? ['people', 'ensembles', 'organisations', 'locations', 'events'] as TypePath[]
          : [this.type];
      },
      entities() {
        return (this.value ?? [])
          .filter(({ type }) => this.entityTypes.indexOf(type) >= 0)
          .sort(({ label: labelA }, { label: labelB }) => (labelA < labelB ? -1 : 1));
      },
      itemCols() {
        return colsMap[`${!!this.vertical}`][this.$vuetify.breakpoint.name];
      }
    },
    watch: {
      async addingSearch(inputValue) {
        const { hits } = await getSearchIndex().search(inputValue, {
          filters: this.entityTypes.map((type) => `type:${type}`).join(' OR ')
        });
        this.searchList = [
          ...(this.searchList ?? []),
          ...(hits as unknown as Entity[])
        ];
      }
    },
    components: { EntityResult }
  });
