import Vue from 'vue';
import {
  getCurrentUser,
  confirmRecoverPassword,
  confirmSignup,
  login,
  logout,
  recoverPassword,
  signUp
} from '@/auth/cognito';
import {
  getLoginPath,
  getProfilePath,
  getRecoverPasswordConfirmationPath,
  getRecoverPasswordPath,
  getRouteReturnPath,
  getSignupConfirmationPath,
  getSignupPath,
  isAuthPath
} from '@/auth/paths';
import { deregisterAuthCallback, registerAuthCallback } from '@/auth/callbacks';
import { AuthCallback, UserDetails, VueCognitoAuth } from '@/auth/types';

export const install = async (): Promise<void> => {
  Object.defineProperty(Vue.prototype, '$auth', {
    get: (): VueCognitoAuth => ({
      login,
      logout,
      signUp,
      confirmSignup,
      recoverPassword,
      confirmRecoverPassword,
      isAuthPath,
      getRouteReturnPath,
      getLoginPath,
      getSignupPath,
      getSignupConfirmationPath,
      getRecoverPasswordPath,
      getRecoverPasswordConfirmationPath,
      getProfilePath
    })
  });

  const observableUser = Vue.observable(getCurrentUser());

  Object.defineProperty(Vue.prototype, '$user', {
    get: (): UserDetails => observableUser
  });

  registerAuthCallback(() => {
    const currentUser = getCurrentUser();
    observableUser.cognitoUser = currentUser.cognitoUser;
    observableUser.attributes = currentUser.attributes;
    observableUser.groups = currentUser.groups;
  });

  Vue.mixin({
    beforeCreate(this: Vue & { authCallbackId?: number }) {
      if (typeof this.$options?.authEvent === 'function') {
        this.authCallbackId = registerAuthCallback((type, message) => {
          (this.$options.authEvent as AuthCallback).call(this, type, message);
        });
      }
    },
    destroyed(this: Vue & { authCallbackId?: number }) {
      if (this.authCallbackId) {
        deregisterAuthCallback(this.authCallbackId);
        delete this.authCallbackId;
      }
    }
  });
};
