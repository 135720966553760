
  import Vue from 'vue';
  import { None } from '@/util/types';

  interface Props {
    redirect: string;
  }

  export default Vue.extend<None, None, None, Props>({
    name: 'RedirectGuard',
    props: {
      redirect: {
        type: String,
        required: true
      }
    },
    mounted() {
      if (this.redirect) {
        this.$router.push(this.redirect);
      }
    }
  });
