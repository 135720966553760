
  import Vue from 'vue';
  import { requestImport } from '@/services/api/requestImport';
  import PassiveMessage from '@/components/PassiveMessage.vue';
  import { DataTableHeader } from '@/util/types';

  interface Data {
    brns: string;
    importRequests: string[];
    activity: boolean;
    message: string | undefined;
    error: boolean;
  }

  interface Methods {
    submit(): Promise<void>;
    reset(): void;
  }

  interface Computed {
    headers: DataTableHeader[];
  }

  export default Vue.extend<Data, Methods, Computed>({
    name: 'SlwaImport',
    data() {
      return {
        brns: '',
        importRequests: [] as string[],
        activity: false,
        message: undefined as string | undefined,
        error: false
      };
    },
    methods: {
      async submit() {
        const requests = this.brns.trim()
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line.length > 0);
        if (requests.length === 0) { return; }
        const invalidRequests = requests.filter((request) => !/^b\d{7}(?:_\d)?$/.test(request));
        if (invalidRequests.length > 0) {
          this.error = true;
          this.message = `Invalid BRNs: ${invalidRequests.join('; ')}. Correct format is "b1234567" or "b1234567_2"`;
          return;
        }
        this.activity = true;
        this.message = undefined;
        try {
          this.message = await requestImport(this.$apolloProvider, {
            source: 'SLWA',
            requests
          });
          this.error = false;
          this.brns = '';
        } catch (e) {
          this.message = `Failed to request import: ${e}`;
          this.error = true;
        } finally {
          this.activity = false;
        }
      },
      reset() {
        this.brns = '';
        (this.$refs.slwaBrnsInput as HTMLTextAreaElement).focus();
      }
    },
    computed: {
      headers() {
        return [
          {
            text: 'Source',
            value: 'source',
            sortable: false
          },
          {
            text: 'ID',
            value: 'id',
            sortable: false
          },
          {
            text: 'Date',
            value: 'timestamp',
            sortable: true
          }
        ];
      }
    },
    components: {
      PassiveMessage
    }
  });
