
  import Vue from 'vue';
  import { Label, Path, TypePath } from '@/api-schema';
  import { getEntityTypes, isMediaType } from '@/util/entityTypes';
  import { getSlug } from '@/util/urls';

  interface Data {
    displayDialog: boolean;
    targetType?: TypePath;
    targetSlug?: Path;
    targetLabel?: Label;
    targetSlugLocked: boolean;
  }

  interface Methods {
    confirmMove(): Promise<void>;
  }

  interface Computed {
    availableEntityTypePathsForMove: TypePath[];
    resultingUrl: Path;
    labelToSave: Label;
    isEmptyMove: boolean;
  }

  interface Props {
    type: TypePath;
    slug: Path;
    label: Label;
    disabled?: boolean;
    moveEntity(type: TypePath, slug: Path, label: Label): Promise<void>;
  }

  export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'MoveEntity',
    props: {
      type: {
        type: String as () => TypePath,
        required: true
      },
      slug: {
        type: String as () => Path,
        required: true
      },
      label: {
        type: String as () => Label,
        required: true
      },
      disabled: {
        type: Boolean,
        required: false
      },
      moveEntity: {
        type: Function as unknown as () => (type: TypePath, slug: Path, label: Label) => Promise<void>,
        required: true
      }
    },
    data() {
      return {
        displayDialog: false,
        targetType: undefined as TypePath | undefined,
        targetSlug: undefined as Path | undefined,
        targetLabel: undefined as Label | undefined,
        targetSlugLocked: false
      };
    },
    methods: {
      async confirmMove() {
        if (!this.targetType || !this.targetSlug || !this.targetLabel) {
          return;
        }
        await this.moveEntity(this.targetType as TypePath, this.targetSlug as Path, this.labelToSave as Label);
        this.displayDialog = false;
      }
    },
    computed: {
      availableEntityTypePathsForMove() {
        if (isMediaType(this.type)) {
          return [this.type];
        }
        return getEntityTypes()
          .map(({ path }) => path.substring(1) as TypePath)
          .filter((type) => !isMediaType(type));
      },
      resultingUrl() {
        return `/${this.targetType}/${this.targetSlug}`;
      },
      labelToSave() {
        return this.targetLabel?.trim() || '';
      },
      isEmptyMove() {
        return this.type === this.targetType && this.slug === this.targetSlug && this.label === this.labelToSave;
      }
    },
    watch: {
      displayDialog(value) {
        if (!value) {
          return;
        }
        this.targetType = this.type;
        this.targetSlug = this.slug;
        this.targetLabel = this.label;
        this.targetSlugLocked = false;
      },
      targetLabel(value) {
        if (!this.targetSlugLocked) {
          this.targetSlug = this.labelToSave === this.label ? this.slug : getSlug(value);
        }
      }
    }
  });
