import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { EditEntityInput, Entity, TypePath } from '@/api-schema';
import { articleFields, commonFields, mediaFields } from '@/services/api/helpers/fields';
import { isMediaType } from '@/util/entityTypes';
import { deepCopy } from '@/util/deep';
import { stripMetadata } from '@/services/api/helpers/metadata';

const editArticleEntityMutation = gql`
  mutation EditArticleEntity($input: EditEntityInput!) {
    entity: editEntity(input: $input) {
      ${commonFields}
      ${articleFields}
    }
  }
`;

const editMediaEntityMutation = gql`
  mutation EditArticleEntity($input: EditEntityInput!) {
    entity: editEntity(input: $input) {
      ${commonFields}
      ${mediaFields}
    }
  }
`;

export const editEntity = async (
  apolloProvider: ApolloProvider,
  input: EditEntityInput,
  type: TypePath
): Promise<Entity> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: isMediaType(type) ? editMediaEntityMutation : editArticleEntityMutation,
    variables: { input }
  });
  return deepCopy(stripMetadata(result.data.entity));
};
