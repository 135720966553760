import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { Entity } from '@/api-schema';
import { deepCopy } from '@/util/deep';

const listEntitiesWithProposedRevisionsQuery = gql`
  query ListEntitiesWithProposedRevisions {
    entities: listEntitiesWithProposedRevisions {
      path
      type
      label
      lastModified {
        by
        at
      }
      proposedRevisions {
        username
        lastModified {
          by
          at
        }
      }
    }
  }
`;

export const listEntitiesWithProposedRevisions = async (
  apolloProvider: ApolloProvider
): Promise<Entity[]> => {
  const result = await apolloProvider.defaultClient.query({
    query: listEntitiesWithProposedRevisionsQuery,
    fetchPolicy: 'no-cache'
  });
  return deepCopy(result.data.entities);
};
