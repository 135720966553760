
  import Vue from 'vue';
  import { None } from '@/util/types';

  const lookupTable = [
    '#000', '#a00', '#0a0', '#00a', '#aa0', '#a0a',
    '#0aa', '#aaa', '#800', '#080', '#008', '#880',
    '#808', '#088', '#888', '#a80', '#0a8', '#80a',
    '#8a0', '#08a', '#a08', '#aa8', '#a8a', '#8aa'
  ];

  interface Props {
    tag: string;
    remove?(tag: string): void;
  }

  interface Computed {
    color: string;
    searchUrl: string;
    small: boolean;
    xSmall: boolean;
  }

  export default Vue.extend<None, None, Computed, Props>({
    name: 'TagChip',
    props: {
      tag: {
        type: String,
        required: true
      },
      remove: {
        type: Function as unknown as () => (tag: string) => void,
        required: false
      }
    },
    computed: {
      color(): string {
        const digest = this.tag
          .split('')
          .reduce<number>((result, character) => result + character.charCodeAt(0), 0);
        return lookupTable[digest % lookupTable.length];
      },
      searchUrl() {
        return `/search?tags[0]=${this.tag}`;
      },
      small(): boolean {
        return this.$vuetify.breakpoint.lgAndUp;
      },
      xSmall(): boolean {
        return this.$vuetify.breakpoint.mdAndDown;
      }
    }
  });
