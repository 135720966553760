
  import Vue from 'vue';
  import { None } from '@/util/types';

  interface Props {
    label?: string;
    activity?: boolean;
  }

  export default Vue.extend<None, None, None, Props>({
    name: 'Heading',
    props: {
      label: {
        type: String,
        required: false
      },
      activity: {
        type: Boolean,
        required: false
      }
    }
  });
